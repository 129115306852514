.nav-logo{
    height: 5vmin;
    margin-left: 20px;
    

}
  .nav-links{
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10%;
    width: 60%;
    
    
}

  .nav-links ul{

    text-align: center;
    flex-direction: column;
    align-items: center;
    font-size: 2vmin;
  }
  
  #nav_offre:hover .sub-nav{
    
    transition-duration: 2s;
    opacity: 1;
    
  }

  .Nav button{
    font-family: "montserrat";
    background: none;
    border-style: none;
    font-weight: 500;
    letter-spacing: 1.2px;
    font-size: 15px;
  }
  
  
  .sub-nav{
    opacity: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-self: center;
    font-size: 0.9em;
    margin-top: 0px;
    background: white;
    padding: 2%;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.308);
    border-style: solid;
    border-width: 0.2em;
    border-color: #3e8ce6;
    border-radius: 3px;
    border-top-style: none;
    z-index: 0;
    margin-left: -7%;
    letter-spacing: 0.1em;
    transition-duration: 2s;
    

  }

  .Nav .title_logo{
    letter-spacing: 0px;
  }

  .Nav{

    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.308);
    padding: 1%;
    background: linear-gradient(to bottom,#fffefe 88%,#ffffff00);
    font-family: 'Montserrat', serif;
    letter-spacing: 1.5px;
    
    font-weight: 500;
    position: fixed;
    z-index: 100;
    top: 0;
    width: 100%;
    height: 2.5em;
    display: flex;
    justify-content: space-between;
    
    
  }
  .title_logo{
    margin-left: 9px;
    margin-top: 3px;
    opacity: 0.7;
  }
  .title_logo content{
   
    font-size: 14px;
    


  }
  .title_logo content2{
    font-size: 8px;
    font-style: italic;
  }
  .mobile_on{
    display: none;
  }
  

  @media screen and (max-width:640px) and (min-width:200px){
    .Nav{
      max-width: 100vw;
      box-sizing: border-box;
      display: flex;
      justify-content: space-around;
      top: none;
      bottom: 0;
      
      padding: 0;

    }
    
    .title_logo{
      display: none;
    }
    .nav-links{
      
      margin-left: 0px;
      padding: 0px;
      font-size: 1px;
      
      margin-left: -400px;
      box-sizing: border-box;
    }
    .nav-links ul{

      width: 10vw;



    }
    .nav-logo{
      margin-left:0px;
    }
    
    .mobile_on{
      display: flex;
    }
    .mobile_off{
      display: none;
    }
    .Nav button{
      font-size: 1em;
    }
    body{
      max-width: 100%;
      overflow-x: hidden;
    }
  }