.image_methode{
    width: 100px;
    height: 100px;
    border-radius: 100px;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.267);
    margin: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #3e8ce6;
    

}
.image_methode .fa-solid{
    padding: 0px;
    margin: 0px;
    color: white;
    
    
}
#method_background{
    background: white;
    padding: 1%;
    border-radius: 1%;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.267);
}
.image_methode_2{

    height: 300px;
    width: 400px;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.267);
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;

}

.valeur{
    margin-top: 3%;
    margin-bottom: 3%;
}
.valeur #box_content {
   max-width: 280px;
   
}

.methode_text {
    margin: 35px;
    margin-top: 15px;
    max-width: 550px;
    background: white;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.267);
    padding: 30px;
    z-index: 1;
   
    border-radius: 4px;
}

#image_coaching_2{
    margin-left: 50px;
    z-index: 0;
}

@media screen and (max-width:640px) and (min-width:200px){
  

 .valeur{
     flex-flow: column;
     align-items: center;
     
 } 
 .valeur *{
     margin-top: 10px;
     margin-bottom: 10px;
 }
 .methode_part2{
     flex-flow: column;
 }
 .image_methode_2{
     display: none;
 }
  #box_content content1{
      font-size: 0.7em;
  }
  .methode_text content1{

    font-size: 0.7em;
  }

}