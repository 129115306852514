@import url(https://fonts.googleapis.com/css2?family=MontserratMontserrat:wght@600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-logo{
    height: 5vmin;
    margin-left: 20px;
    

}
  .nav-links{
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10%;
    width: 60%;
    
    
}

  .nav-links ul{

    text-align: center;
    flex-direction: column;
    align-items: center;
    font-size: 2vmin;
  }
  
  #nav_offre:hover .sub-nav{
    
    transition-duration: 2s;
    opacity: 1;
    
  }

  .Nav button{
    font-family: "montserrat";
    background: none;
    border-style: none;
    font-weight: 500;
    letter-spacing: 1.2px;
    font-size: 15px;
  }
  
  
  .sub-nav{
    opacity: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-self: center;
    font-size: 0.9em;
    margin-top: 0px;
    background: white;
    padding: 2%;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.308);
    border-style: solid;
    border-width: 0.2em;
    border-color: #3e8ce6;
    border-radius: 3px;
    border-top-style: none;
    z-index: 0;
    margin-left: -7%;
    letter-spacing: 0.1em;
    transition-duration: 2s;
    

  }

  .Nav .title_logo{
    letter-spacing: 0px;
  }

  .Nav{

    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.308);
    padding: 1%;
    background: linear-gradient(to bottom,#fffefe 88%,#ffffff00);
    font-family: 'Montserrat', serif;
    letter-spacing: 1.5px;
    
    font-weight: 500;
    position: fixed;
    z-index: 100;
    top: 0;
    width: 100%;
    height: 2.5em;
    display: flex;
    justify-content: space-between;
    
    
  }
  .title_logo{
    margin-left: 9px;
    margin-top: 3px;
    opacity: 0.7;
  }
  .title_logo content{
   
    font-size: 14px;
    


  }
  .title_logo content2{
    font-size: 8px;
    font-style: italic;
  }
  .mobile_on{
    display: none;
  }
  

  @media screen and (max-width:640px) and (min-width:200px){
    .Nav{
      max-width: 100vw;
      box-sizing: border-box;
      display: flex;
      justify-content: space-around;
      top: none;
      bottom: 0;
      
      padding: 0;

    }
    
    .title_logo{
      display: none;
    }
    .nav-links{
      
      margin-left: 0px;
      padding: 0px;
      font-size: 1px;
      
      margin-left: -400px;
      box-sizing: border-box;
    }
    .nav-links ul{

      width: 10vw;



    }
    .nav-logo{
      margin-left:0px;
    }
    
    .mobile_on{
      display: flex;
    }
    .mobile_off{
      display: none;
    }
    .Nav button{
      font-size: 1em;
    }
    body{
      max-width: 100%;
      overflow-x: hidden;
    }
  }
.Footer{
    background: linear-gradient(to right,#c1eaf8 1%,#3e8ce6);;
    padding-top: 50px;
    padding-bottom: 2%;
  
    
    
   
    }
  .Footer * {

    color: white;

  }
    .Footer .nav-logo{ 
      margin-left: 5%;
      }
    
    .foot-links{
    
    margin-right: 30px;
    
    }
    #foot_content{
    
    margin-right: 50px;
    
    }
    #foot_content *{
  
    padding-bottom: 14px;
  
    }
    ftitle{
      font-size: 22px;
      margin-bottom: 10px;
      font-weight: 500;
    }
    @media screen and (max-width:640px) and (min-width:200px){
      .Footer{
        display: none;
      }
    }
body{
    max-width: 100%;
    overflow-x: hidden;
    
}




.section{
    
    background: linear-gradient(to right,#c1eaf8 40%,#3e8ce6);
    padding:30px;
    height: 70px;
    font-weight: 600;
    

  }

.section_title{
    
    width: 40%;
    z-index: 2;
    background: linear-gradient(to right,#c1eaf8 40%,#3e8ce6);
    padding:30px;
    height: 30px;
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.308);
}
.section_title h2title{
    
    
    font-size: 1.5em;

}
.section_background{
    width: 90%;
    z-index: 1;
    margin-left: 2%;
    margin-top: -9%;
    background: linear-gradient(to right,white 80%,rgba(255, 255, 255, 0)) ;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.096);
    padding:30px;
    height: 60px;
    
    
}

.underline2{
    height: 1.7vmax;
    width: 70%;
    z-index: 10;
    background-color: rgb(255, 204, 109);
}


.background_decoration{

    position: absolute;
        z-index: 0;
        background: linear-gradient(to top,#c1eaf8 40%,#3e8ce696);
        opacity: 0.4;
}



.name_boxes{
    
    
    font-weight: 900;
    font-size: 25px;
    color: #ffffff;
    border-radius: 4px;
    padding: 10px;
    line-height: 23px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.308);
    padding-right: 16px;
}
#quentin_name{

    height: 70px;
    width: 120px;
    background: #12d0ff;
    left: 81%;
    top: 22%;
    position: absolute;
    
}
#jules_name{

    height: 50px;
    width: 120px;
    background: #1281ff;
    left: 67%;
    top: 77%;
    position: absolute;
    
}
#carole_name{

    height: 50px;
    width: 120px;
    background: #146cd1;
    left: 59%;
    top: 140%;
    position: absolute;
    
}
.name_boxes content2{
    font-size: 15px;
    line-height: 10px;
}
.contact_photo{

    height: 230px;
    width: 300px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.308);
    border-radius: 3px;
    z-index: 0;
    margin-top: -25px;
    margin-left: 160px;
    border-style: solid;
    border-width: 3px;
    border-color: #146cd1;
    

}
  


.content_blue{

    transition-duration: 1s;
    background-color: #E5F8FF;
    border-radius: 10px;
    height: auto;
    max-width: 30em;
    padding: 20px;
    padding-bottom: 60px;
    border-top-style: solid;
    border-top-width: 23px;
    border-top-color: #2873C9;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.308);
    font-weight: 600;
    font-size: 1em;
    line-height: 1.5em;
    }

    .content_white{
      
      height: auto;
      width: 30%;
      padding: 20px;
      padding-bottom: 60px;
      border-left-style: solid;
      border-left-width: 6px;
      border-left-color: #2873C9;
      font-family: "montserrat";
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;

      }

      #bouton{

        background-color: #2873C9;
        box-sizing: border-box;
        color: white;
        margin-top: -2vmax;
        border-style: solid;
        border-color: #c1eaf8;
        border-width: 5px;
        padding: 1.2vmax 2.4vmax;
        text-decoration: none;
        font-weight: 700;
        font-family: 'Montserrat';
        font-size: 1.2vmax;
        border-radius: 9px;
        
        
        
       }
       #bouton2{
    
        background-color: white;
        background-origin: border-box;
        box-sizing: border-box;
        color: #2873C9;
        padding: 12px 26px;
        text-decoration: none;
        font-weight: 700;
        font-family: 'Montserrat';
        font-size: 17px;
        border-style: solid;
        border-color: rgba(255, 194, 61, 0.664);
        border-width: 7px;
        border-radius: 9px;
        margin-left: -20px;
        
        
       }

       @media screen and (max-width:640px) and (min-width:200px){

        .article_content {
            max-width: 280px;
            margin-left: -25px;
            font-size: 1em;
        }
        .article_content img{
            width: 300px;

        }

        #bouton{
          
          font-size: 2vmax;
          background-color: #2873C9;
        padding: 5vmax 5vmax;
       
        font-weight: 600;
        
        border-radius: 9px;
        border-width: 5px;
       
         
          
        }
        
        .content_blue{

            font-size: 0.6em;
            
            }
        .content_blue content{
            font-size: 0.9em;
        }
        .section h1title{
            font-size: 1em;
        }
        .section{
            height: 40px;
            
        }
        .underline2{
            width: 200px;
        }
    
    }
      
     
.contact_info{

    background: white;
    padding: 20px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.308);
    border-radius: 3px;
    font-size: 16px;

}

.fa-solid{

    margin-right: 10px;
}


@media screen and (max-width:640px) and (min-width:200px){

    .Contact h1title{
        font-size: 20px;
    }
    #title{
        margin-top: -10px;
        margin-bottom: 20px
    }

    .contact_info{
        font-size: 14px;
        
    }

    

    .formulaire_contact{
        width: 75vw;
        margin-left: 0;
       
        

    }
    .formulaire_contact content{
        font-size: 11px;
    }
    #input{

        height: 5vw;
        width: 25vw;
        font-size: 11px;
        

    }
    .btn{
        margin-top: 10px;
        padding: 9px;
        width: 120px;
        font-size: 14px;
    }
    #message{
        width: 60vw;
        height: 14vw;

    }
    #mapmessage{
        width: 70vw;
    }

    #contact_telephone{

        flex-flow: column-reverse;
        align-items: center;
        
        
    }

    #contact_telephone form{
    
        margin-top: 50px;
        margin-left: -200px;
    }


}


.react-calendar { 
  width: 400px;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border-radius: 12px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  font-family: 'Montserrat';
  
  line-height: 0.3em;
  border-style: solid;
  border-color: #3e8ce6;
  border-width: 6px;
 }
 .react-calendar__navigation button {
  color: #3e8ce6;
  min-width: 44px;
  background: none;
  font-size: 14px;
  font-weight: 700;
  font-style: "Montserrat";
  padding-top: 0;
  
  
 }
 .react-calendar__navigation button:enabled:hover,
 .react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
 }
 .react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
 }
 abbr[title] {
  text-decoration: none;
 }
 /* .react-calendar__month-view__days__day--weekend {
  color: #d10000;
 } */
 .react-calendar__tile:enabled:hover,
 .react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #3e8ce6;
  border-radius: 6px;
 }
 .react-calendar__tile--now {
  background: none;
  border-radius: 6px;
  font-weight: bold;
  color: #000000;
 }
 .react-calendar__tile--now:enabled:hover,
 .react-calendar__tile--now:enabled:focus {
  background: #6f48eb33;
  border-radius: 6px;
  font-weight: bold;
  color: #3e8ce6;
 }
 .react-calendar__tile--hasActive:enabled:hover,
 .react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
 }
 .react-calendar__tile--active {
  background: #3e8ce6;
  border-radius: 6px;
  font-weight: bold;
  color: white;
 }
 .react-calendar__tile--active:enabled:hover,
 .react-calendar__tile--active:enabled:focus {
  background: #3e8ce6;
  color: white;
 }
 .react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
 }
 .react-calendar__tile--range {
  background: #f8f8fa;
  color: #3e8ce6;
  
 }
 .react-calendar__tile--rangeStart {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #3e8ce6;
  color: white;
 }
 .react-calendar__tile--rangeEnd {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #3e8ce6;
  color: white;
 }




 



.hourlistselection{

    display: flex;
    flex-wrap: wrap;
    width: 350px;
    background: rgb(255, 245, 245);
    justify-content: center;
    
}
.rdvlist{
    
    width: 800px;
    height: 800px;
    justify-content: space-around;
    
    
}
.rdv{
    display: flex;
    justify-content: space-around;
    margin-left: 10%;
    width: 80%;
}
.rdvlist content{
    
    font-size: 15px;
    
    
    
}
.booked_rdv{
    padding: 30px;
    background: #d1f1fd;
}
.selected{
    border-style: solid;
    border-color: #2873C9;
    border-width: 4px;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.308);
}
.offhour{
    color: black;
    background-color: #d4ddd7;
    pointer-events: none;
    

}




.manage_dispo{
    height: 20px;
    
    background: white;
}
#confirm{
    margin-left: -100%;
    padding-bottom: 80px;
    margin-bottom: 50px;
    margin-top: 10%;
    border-style: solid;
    border-color:  #d0f0fc;
    border-width: 10px;
  padding: 4%;
  width: 100%;
  border-radius: 9px;
  font-weight: 500;
  font-size: 13px;
  background: white;
  align-self: center;
  text-align: center;
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.267);
  
  
  
  }
    
  
  .rdv .content_blue{

    padding-bottom: 0%;
    align-items: center;
    box-shadow: none;
    width: 100%;
    align-self: center;
  
  }

@media screen and (max-width:640px) and (min-width:200px){
    #mobile_rdv{
        display: flex;
        align-items: center;
    }
    .datetime{
        padding: 0px;
        margin: 0px;
        width: 100%;
        
    }
    #mobile_coord{
        background: none;
        align-items: flex-start;
    }
    #confirm{
        margin-left: 0%;
        border-style: none;
        width: 180%;
    }
    #schema_step{
        display: none;
    }
    #schema_rdv{
        display: none;
    }
    .note{display: none;}

.rdv{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.rdv form{
    margin-top: 10%;
}
#calendar_id{
    height: 20vmin;
}
#bouton{
    padding:1.2vmax;
    width: auto;
    margin: 0px;
}

}
#coach_photo{
  
margin-top: 4%;
height: 37vmax;

border-radius: 5px;
box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.308);

}

#coach_description{
  
    width: 36vmax;
    border-radius: 5px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.308);
    padding-left: 20px;
    padding-top: 20px;
    overflow-y: scroll;
    
    
    
    }
    #coach_message1{
      z-index: 1;
    }
    
    #coach_message1 .content_blue{
    
      
      border-style: none;
      font-weight: 500;
     
    width: 60vw;
    
    }
    #coach_message1 :last-child{
      align-self: center;
     
    }
    #coach_message1 #icon{
      margin-left: 35px;
      margin-top: 17px;
      font-size: 28px;
    }
   
  
  #coach_message2 .content_blue {
    
    margin-left: -4%;
    font-size: 1vmax;
    font-weight: 500;
    z-index: 0;
    margin-top: 1%;
    background: white;
    box-shadow: none;
    
  }
  
  #coach_message2{

    background: white;
    margin-left: -3%;
    padding: 1%;
    padding-left: 5%;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.308);
    z-index: 0;
    
    
  }
  #coach_message3{
    background: white;
    padding: 4%;
    margin-top: 3%;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.308);
  }

    .photo_box{
      width: 77%;
      box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.308);
      border-radius: 5px;

    }

    
    .photo_avatar{

      
      height: 10vmin;
      width: 10vmin;
      background: url(/static/media/coach_solo.41f19c0a.svg);
      border-radius: 9px;
      background-size: contain;
      background-repeat: no-repeat;
      margin-top: -15%;
      margin-left: 68%;
      z-index: 10;
      
    
    }

    .coach_description_viewport{
  
        height: 43vmax;
        

        
        
        
        }

        .coach_description_viewport::-webkit-scrollbar {
            width: 12px; /* width of the entire scrollbar */
          }
          
          #coach_description::-webkit-scrollbar-track {
            background: rgb(245, 245, 245); /* color of the tracking area */
          }
          
          #coach_description::-webkit-scrollbar-thumb {
            background-color:  #2873c95d; /* color of the scroll thumb */
            border-radius: 20px; /* roundness of the scroll thumb */
             /* creates padding around scroll thumb */
             border-width: 2px;
             border-style: solid;
             border-color: rgb(245, 245, 245);
          }


    .livre_eyrolles{

        width: 100px;
        height: 130px;
        
        margin-top: 10px;
    }

    .decoration{
        position: absolute;
        z-index: 0;
        height: 400px;
        width: 500px;
        top: 100px;
        margin-left: -400px;
        background: linear-gradient(to top,#c1eaf8 40%,#3e8ce696);
        opacity: 0.4;
        border-radius: 300px;
        border-top-right-radius: 200px;

          }

          .decoration2{
            position: absolute;
            z-index: 0;
            height: 400px;
            width: 500px;
            top: 500px;
            
            margin-left: -400px;
            background: linear-gradient(to top,#c1eaf8 40%,#3e8ce696);
            opacity: 0.4;
            border-bottom-right-radius: 150px;
            border-top-right-radius: 400px;
            
    
              }

            .decoration3{
            position: absolute;
            z-index: 0;
            height: 400px;
            width: 100px;
            top: 200px;
            right: 0px;
            margin-left: -400px;
            background: linear-gradient(to top,#c1eaf8 40%,#3e8ce696);
            opacity: 0.4;
            border-bottom-left-radius: 150px;
            border-top-left-radius: 400px;
            
    
              }

              .decoration4{
                position: absolute;
                z-index: 0;
                height: 100px;
                width: 100px;
                top: 100px;
                right: 150px;
                
                background: linear-gradient(to top,#c1eaf8 40%,#3e8ce696);
                opacity: 0.4;
                border-radius: 200px;
                
        
                  }

                  .decoration5{
                    position: absolute;
                    z-index: 0;
                    height: 50px;
                    width: 50px;
                    top: 150px;
                    left: 150px;
                    
                    background: linear-gradient(to top,#c1eaf8 40%,#3e8ce696);
                    opacity: 0.4;
                    border-radius: 200px;
                    
            
                      }
@media screen and (max-width:700px){

         #coach_block_1{
           display: flex;
           flex-direction: column-reverse;
           align-items: center;
           justify-content: center;
         }
         #coach_block_2{
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          justify-content: center;
        }
         .photo_box{
           margin:0px;

         }
         .content_blue content{
           font-size: 8.5px;
         }
         .content_blue{
           max-width: none;
           width: 80vw;
         }
         #coach_message2{
           margin-bottom: 20px;
           background: none;
           border-style: none;
           box-shadow: none;
         }
         #coach_message1 .content_blue{
    
      
          
        width: 80vw;
        
        }
         

         .mobile_off{
           display: none;
         } 

         #bouton{
          padding: 7px;
          
          margin-top: 10px;
          margin-left: 35%;
          
        }
        #bouton content{
          padding-left: 20px;
          padding-right: 20px;
          font-size: 12px;
        }

}
    
.image_methode{
    width: 100px;
    height: 100px;
    border-radius: 100px;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.267);
    margin: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #3e8ce6;
    

}
.image_methode .fa-solid{
    padding: 0px;
    margin: 0px;
    color: white;
    
    
}
#method_background{
    background: white;
    padding: 1%;
    border-radius: 1%;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.267);
}
.image_methode_2{

    height: 300px;
    width: 400px;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.267);
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;

}

.valeur{
    margin-top: 3%;
    margin-bottom: 3%;
}
.valeur #box_content {
   max-width: 280px;
   
}

.methode_text {
    margin: 35px;
    margin-top: 15px;
    max-width: 550px;
    background: white;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.267);
    padding: 30px;
    z-index: 1;
   
    border-radius: 4px;
}

#image_coaching_2{
    margin-left: 50px;
    z-index: 0;
}

@media screen and (max-width:640px) and (min-width:200px){
  

 .valeur{
     flex-flow: column;
     align-items: center;
     
 } 
 .valeur *{
     margin-top: 10px;
     margin-bottom: 10px;
 }
 .methode_part2{
     flex-flow: column;
 }
 .image_methode_2{
     display: none;
 }
  #box_content content1{
      font-size: 0.7em;
  }
  .methode_text content1{

    font-size: 0.7em;
  }

}
.offre_note_content{

    line-height: 18px;
    text-align: left;
    margin-left: 30px;
}
.tab_offre_text{
    width: 60%;
    padding-bottom: 30px;
    font-weight: 500;
    margin-left: 100px;
}
.offre_text{
    width: 400px;
}
.offre_text content1{
    letter-spacing: 1px;
    line-height: 20px;
    padding: 20px;
    border-left-style: solid;
    border-left-color: #2873C9;
    border-left-width: 10px;
    padding-bottom: 15px;
    box-shadow: 2px 5px 0.5px rgba(0, 0, 0, 0.041);
    margin-bottom: 70px;
    background-color: white;

}
.offre_content{

    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;

}

.Offre{
    
    
    padding-top: 15vh;
    padding-bottom: 10%;
    
  
  }
  
  
  .photo img{
    width: 50%;
    
  }
  
  #offre_box #dropbar{

   height: 140px;
   width: 5%;
   background:linear-gradient(to bottom,#c1eaf8 90%,#ffffff00);
   border-color: #2873C9;
   margin-top: -10%;
   z-index: 0;
   align-self: center;

 

  }
  .detail_offre{

   width: 80%;
   align-self: center;
   padding-top: 7%;

  }
  .detail_offre .note{

    background: white;
    margin-top: -200px;
 
   }

  
  .detail_offre .content_blue{

    padding-bottom: 180px;
    width: 100%;
    background: linear-gradient(to bottom,#E5F8FF 70%,#ffffff00);
    
 
   }

   @media screen and (max-width:640px) and (min-width:200px){

       .offre_text{
           width: 70vmin;
       }
       .offre_text content1{
           font-size: 0.7em;
       }

       .offre_text h2under2{
        width: 50vmin;
        height: 5vmin;
    }
       .home_offre {
           margin-right: 8vmin;
       }
       

       #offre_content_telephone{

        flex-flow: column;
        align-items: center;

       }
   }




  
body{
  font-weight: 500;
}


.Test{
  background-color: rgb(255, 251, 242);
  width: 100%;
  overflow-x: hidden;
 



}





#wrap {
  width: 100%;
  height: 50px;
  margin: 0;
  z-index: 99;
  position: relative;
  background-color: #ffffff;
  
  
}


@font-face{
  font-family: montserrat;
  src: url(/static/media/Montserrat-VariableFont_wght.c28a96c3.ttf);
}

@font-face{
  font-family: montserrat Alternates;
  src: url(/static/media/MontserratAlternates-Regular.71e43581.ttf);
}

  





  

  
  



  .carole_coach{

    width: 170px;
    height: 75px;
    position: absolute;
    background-image: url("/static/media/carole, la coach.84517463.svg");
    background-size: cover;
    margin-left: 26%;

  }

  
  
  
    /*  objectif text  */

    .objectif_background{

      background: url(/static/media/Homepage_fond.361fa407.svg);
      background-size: auto;
      background-position-y: 0px;
      
      
    }

    
    
        #objectif_content {

            padding-top: 70px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 600px;
            
          
        }
        #objectif_graph{
            
            padding: 50px;
            display: flex;
            margin-left: 800px;
            
        }
        .obj-fond{
            margin-left: 1100px;
            position:relative;
    z-index:2;
            height: 200vmin;
            margin-top: -800px;
            
        }
        #objectif_persona {
          
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         justify-content: center;
         width: 500px;
         margin-bottom: 80px;
         

      }

        


  /* bandeau de section */
  
  
  /* Schema homepage */
  
   
  /*offre*/
  
  .design_box{
    width: 50%;
    background: white;
    height: 10em;
    
    
  }
  
  .contextes{
    text-align: center;
    align-self: center;
    width: 80%;
    border-style: solid;
    border-radius: 9px;
    border-color: #2873C9;
    border-width: 8px;
    padding: 20px;
    margin-bottom: 1%;
    z-index: 1;
    font-size: 16px;
    font-weight: 400;
    
    
    

  }
  .offre_contexte{
    background-color: #E5F8FF;
    border-radius: 10px;
    width: 100%;
    padding: 15%;
    text-align: center;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.308);
    font-weight: 600;
    font-size: 14px;
    
    

  }
  #offre_box{
   width: 85%;
   align-self: center;
   
   
  }
  #offre_content{
    box-shadow: none;
    border-top-style: none;

  }
  
  .offre_icon{
    
    height: 120px;
    width: 120px;
    position: absolute;
    margin-left: 370px;
  }
  .pre_offre_icon{

    height: 120px;
    width: 120px;
    margin-top: 20px;
    
    
  }
  
  #changement{
    background: url(/static/media/Graph_changement.22a6b376.svg);
    background-size: cover;
    height: 105px;
    width: 150px;
  }
  #valeur{
    background: url(/static/media/CV.5d16464e.svg);
    background-size: cover;
  }
  #decision{
    background: url(/static/media/graphe_decision.ca2ec1a1.svg);
    background-size: cover;
    background-position-x: -5px ;
    height: 180px;
    
  }

  
  
  




  /* footer */


  


  /*contenu récurrent*/
  .window{
    width: 80%;
    margin-left: 10%;
    
    
  }
  
  
  .Page{

    padding-top: 6em;
    padding-bottom: 4%;
    
  
  }
  .blog_editor{

    height: 1000px;
  
  }
  .editor_container{
    margin-left: 100px;
    margin-right: 100px;
  }
  .title_1{
    font-size: 70;
    display: flex;
  
    }
  

  
  
  
  #blue_content{

  background-color: #E5F8FF;
  border-radius: 10px;
  height: 300px;
  width: 400px;
  padding: 20px;
  margin-top: -5px;
  

  }

  /* graph homepage*/


  
  /*//////////*/


  
  
  #contact{
    margin-top: 20px;
    margin-right: 8%;
  }
  #contact .head_text{
    height: 75px;
    padding-left: 10px;
    margin-top: 20px;
    width: 100%;
  }
  #contact #blue_content{
    height: 440px;
    width: 400px;
    padding: 10px;
    padding-left: 10%;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.267);
    
    
    
  }
  





  


  
  .head_title{

   background-color: white;
   height: 37px;
   font-style: italic;
   padding: 7px;
   font-weight: 600;
   border-radius: 7px;
  }
  .head_text{
    margin-top: 40px;
    height: 210px;
    background-color: white;
    padding-top: 7px;
    padding-left: 8px;
    padding-right: 7px;
    border-style: solid;
    border-color: #2873C9;
    border-radius: 7px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;


   }
   .persona .head_text{
     height: 130px;
     margin-bottom: 20px;
     margin-top: 20px;
     padding-top: 12px;
     font-size: 15px;
   }

  #under{
  
    

      height: 25px;
      width: 350px;
      background-color: rgba(255, 194, 61, 0.664);
      margin-top: -10px;
      position:relative;
      z-index:7;
      
    


  }
  
  
  
   
   
   @-webkit-keyframes defilement-rtl{
    0% {
      -webkit-transform: translate3d(150%,0,0);
              transform: translate3d(150%,0,0);      /* position initiale à droite */
    }
    100% {
      -webkit-transform: translate3d(-150%,0,0);
              transform: translate3d(-150%,0,0);  /* position finale à gauche */
    }
  }
  
  
  
   
   
   @keyframes defilement-rtl{
    0% {
      -webkit-transform: translate3d(150%,0,0);
              transform: translate3d(150%,0,0);      /* position initiale à droite */
    }
    100% {
      -webkit-transform: translate3d(-150%,0,0);
              transform: translate3d(-150%,0,0);  /* position finale à gauche */
    }
  }
   .block{
    padding-top: 40px;
    
  }
  .margin_bottom{padding-bottom: 10%;}
  .margin{
    padding-left: 30px;

  }
   .column_start{
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   
   }
   .column_center{
    display: flex;
    flex-direction: column;
    justify-content: center;

   }
   .column_items_center{
    display: flex;
    flex-direction: column;
    align-items: center;

   }
   .column_items_end{

    display: flex;
    flex-direction: column;
    align-items: flex-end;


   }
   .column{
    display: flex;
    flex-direction: column;
  }
   .flex{
     display: flex;
     align-self: center;
     justify-items: center;
   }

   .wrap{
    
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

   }
   

   .line{
     display: flex;
     align-items: flex-start;
     justify-content: space-around;
     padding-bottom: 60px;
   }

   

   .line_around{display: flex;
    align-items: flex-start;
    justify-content: space-around;
    }
    .line_between{display: flex;
      align-items: flex-start;
      justify-content: space-between;
      }
   

   .row{
    display: flex;
    align-items: flex-start;
  }
  #icon{
    margin-left: 10%;
    margin-top: -10px;
    z-index: 99;
    color: #2873C9;
    font-size: 40px;
    
  }
  
  #objectif_box #icon{
    margin: 0px;
    margin-top: 4px;
    font-size: 13px;
  }
  #icon2{
    margin-left: 27%;
    font-size: 45px;
    position: absolute;
    color: #2873C9;
  }

   
  .note{ 
    margin-bottom: 50px;
    border-style: solid;
    border-color:  #d1f1fd;
    border-width: 8px;
  padding: 2vh;
  width: 70%;
border-radius: 9px;
font-weight: 500;
font-size: 0.95em;
align-self: center;
text-align: center;
background: white;


}
.note_2{ 
  z-index: 2;
  margin-bottom: 50px;
  border-style: solid;
  border-color:  #2873C9;
  border-width: 8px;
padding: 3vh;
width: 50%;

font-weight: 500;
font-size: 1em;
align-self: center;
width: 40%;
background: white;
line-height: 1.5em;
margin-left: 40%;
margin-top: -9%;


}



