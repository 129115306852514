



  
body{
  font-weight: 500;
}


.Test{
  background-color: rgb(255, 251, 242);
  width: 100%;
  overflow-x: hidden;
 



}





#wrap {
  width: 100%;
  height: 50px;
  margin: 0;
  z-index: 99;
  position: relative;
  background-color: #ffffff;
  
  
}


@font-face{
  font-family: montserrat;
  src: url('./Montserrat-VariableFont_wght.ttf');
}

@font-face{
  font-family: montserrat Alternates;
  src: url('./MontserratAlternates-Regular.ttf');
}

  





  

  
  



  .carole_coach{

    width: 170px;
    height: 75px;
    position: absolute;
    background-image: url("./images/carole\,\ la\ coach.svg");
    background-size: cover;
    margin-left: 26%;

  }

  
  
  
    /*  objectif text  */

    .objectif_background{

      background: url('./images/Homepage_fond.svg');
      background-size: auto;
      background-position-y: 0px;
      
      
    }

    
    
        #objectif_content {

            padding-top: 70px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 600px;
            
          
        }
        #objectif_graph{
            
            padding: 50px;
            display: flex;
            margin-left: 800px;
            
        }
        .obj-fond{
            margin-left: 1100px;
            position:relative;
    z-index:2;
            height: 200vmin;
            margin-top: -800px;
            
        }
        #objectif_persona {
          
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         justify-content: center;
         width: 500px;
         margin-bottom: 80px;
         

      }

        


  /* bandeau de section */
  
  
  /* Schema homepage */
  
   
  /*offre*/
  
  .design_box{
    width: 50%;
    background: white;
    height: 10em;
    
    
  }
  
  .contextes{
    text-align: center;
    align-self: center;
    width: 80%;
    border-style: solid;
    border-radius: 9px;
    border-color: #2873C9;
    border-width: 8px;
    padding: 20px;
    margin-bottom: 1%;
    z-index: 1;
    font-size: 16px;
    font-weight: 400;
    
    
    

  }
  .offre_contexte{
    background-color: #E5F8FF;
    border-radius: 10px;
    width: 100%;
    padding: 15%;
    text-align: center;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.308);
    font-weight: 600;
    font-size: 14px;
    
    

  }
  #offre_box{
   width: 85%;
   align-self: center;
   
   
  }
  #offre_content{
    box-shadow: none;
    border-top-style: none;

  }
  
  .offre_icon{
    
    height: 120px;
    width: 120px;
    position: absolute;
    margin-left: 370px;
  }
  .pre_offre_icon{

    height: 120px;
    width: 120px;
    margin-top: 20px;
    
    
  }
  
  #changement{
    background: url('./images/Graph_changement.svg');
    background-size: cover;
    height: 105px;
    width: 150px;
  }
  #valeur{
    background: url('./images/CV.svg');
    background-size: cover;
  }
  #decision{
    background: url('./images/graphe_decision.svg');
    background-size: cover;
    background-position-x: -5px ;
    height: 180px;
    
  }

  
  
  




  /* footer */


  


  /*contenu récurrent*/
  .window{
    width: 80%;
    margin-left: 10%;
    
    
  }
  
  
  .Page{

    padding-top: 6em;
    padding-bottom: 4%;
    
  
  }
  .blog_editor{

    height: 1000px;
  
  }
  .editor_container{
    margin-left: 100px;
    margin-right: 100px;
  }
  .title_1{
    font-size: 70;
    display: flex;
  
    }
  

  
  
  
  #blue_content{

  background-color: #E5F8FF;
  border-radius: 10px;
  height: 300px;
  width: 400px;
  padding: 20px;
  margin-top: -5px;
  

  }

  /* graph homepage*/


  
  /*//////////*/


  
  
  #contact{
    margin-top: 20px;
    margin-right: 8%;
  }
  #contact .head_text{
    height: 75px;
    padding-left: 10px;
    margin-top: 20px;
    width: 100%;
  }
  #contact #blue_content{
    height: 440px;
    width: 400px;
    padding: 10px;
    padding-left: 10%;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.267);
    
    
    
  }
  





  


  
  .head_title{

   background-color: white;
   height: 37px;
   font-style: italic;
   padding: 7px;
   font-weight: 600;
   border-radius: 7px;
  }
  .head_text{
    margin-top: 40px;
    height: 210px;
    background-color: white;
    padding-top: 7px;
    padding-left: 8px;
    padding-right: 7px;
    border-style: solid;
    border-color: #2873C9;
    border-radius: 7px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;


   }
   .persona .head_text{
     height: 130px;
     margin-bottom: 20px;
     margin-top: 20px;
     padding-top: 12px;
     font-size: 15px;
   }

  #under{
  
    

      height: 25px;
      width: 350px;
      background-color: rgba(255, 194, 61, 0.664);
      margin-top: -10px;
      position:relative;
      z-index:7;
      
    


  }
  
  
  
   
   
   @keyframes defilement-rtl{
    0% {
      transform: translate3d(150%,0,0);      /* position initiale à droite */
    }
    100% {
      transform: translate3d(-150%,0,0);  /* position finale à gauche */
    }
  }
   .block{
    padding-top: 40px;
    
  }
  .margin_bottom{padding-bottom: 10%;}
  .margin{
    padding-left: 30px;

  }
   .column_start{
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   
   }
   .column_center{
    display: flex;
    flex-direction: column;
    justify-content: center;

   }
   .column_items_center{
    display: flex;
    flex-direction: column;
    align-items: center;

   }
   .column_items_end{

    display: flex;
    flex-direction: column;
    align-items: flex-end;


   }
   .column{
    display: flex;
    flex-direction: column;
  }
   .flex{
     display: flex;
     align-self: center;
     justify-items: center;
   }

   .wrap{
    
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

   }
   

   .line{
     display: flex;
     align-items: flex-start;
     justify-content: space-around;
     padding-bottom: 60px;
   }

   

   .line_around{display: flex;
    align-items: flex-start;
    justify-content: space-around;
    }
    .line_between{display: flex;
      align-items: flex-start;
      justify-content: space-between;
      }
   

   .row{
    display: flex;
    align-items: flex-start;
  }
  #icon{
    margin-left: 10%;
    margin-top: -10px;
    z-index: 99;
    color: #2873C9;
    font-size: 40px;
    
  }
  
  #objectif_box #icon{
    margin: 0px;
    margin-top: 4px;
    font-size: 13px;
  }
  #icon2{
    margin-left: 27%;
    font-size: 45px;
    position: absolute;
    color: #2873C9;
  }

   
  .note{ 
    margin-bottom: 50px;
    border-style: solid;
    border-color:  #d1f1fd;
    border-width: 8px;
  padding: 2vh;
  width: 70%;
border-radius: 9px;
font-weight: 500;
font-size: 0.95em;
align-self: center;
text-align: center;
background: white;


}
.note_2{ 
  z-index: 2;
  margin-bottom: 50px;
  border-style: solid;
  border-color:  #2873C9;
  border-width: 8px;
padding: 3vh;
width: 50%;

font-weight: 500;
font-size: 1em;
align-self: center;
width: 40%;
background: white;
line-height: 1.5em;
margin-left: 40%;
margin-top: -9%;


}


