body{
    max-width: 100%;
    overflow-x: hidden;
    
}




.section{
    
    background: linear-gradient(to right,#c1eaf8 40%,#3e8ce6);
    padding:30px;
    height: 70px;
    font-weight: 600;
    

  }

.section_title{
    
    width: 40%;
    z-index: 2;
    background: linear-gradient(to right,#c1eaf8 40%,#3e8ce6);
    padding:30px;
    height: 30px;
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.308);
}
.section_title h2title{
    
    
    font-size: 1.5em;

}
.section_background{
    width: 90%;
    z-index: 1;
    margin-left: 2%;
    margin-top: -9%;
    background: linear-gradient(to right,white 80%,rgba(255, 255, 255, 0)) ;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.096);
    padding:30px;
    height: 60px;
    
    
}

.underline2{
    height: 1.7vmax;
    width: 70%;
    z-index: 10;
    background-color: rgb(255, 204, 109);
}


.background_decoration{

    position: absolute;
        z-index: 0;
        background: linear-gradient(to top,#c1eaf8 40%,#3e8ce696);
        opacity: 0.4;
}



.name_boxes{
    
    
    font-weight: 900;
    font-size: 25px;
    color: #ffffff;
    border-radius: 4px;
    padding: 10px;
    line-height: 23px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.308);
    padding-right: 16px;
}
#quentin_name{

    height: 70px;
    width: 120px;
    background: #12d0ff;
    left: 81%;
    top: 22%;
    position: absolute;
    
}
#jules_name{

    height: 50px;
    width: 120px;
    background: #1281ff;
    left: 67%;
    top: 77%;
    position: absolute;
    
}
#carole_name{

    height: 50px;
    width: 120px;
    background: #146cd1;
    left: 59%;
    top: 140%;
    position: absolute;
    
}
.name_boxes content2{
    font-size: 15px;
    line-height: 10px;
}
.contact_photo{

    height: 230px;
    width: 300px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.308);
    border-radius: 3px;
    z-index: 0;
    margin-top: -25px;
    margin-left: 160px;
    border-style: solid;
    border-width: 3px;
    border-color: #146cd1;
    

}
  


.content_blue{

    transition-duration: 1s;
    background-color: #E5F8FF;
    border-radius: 10px;
    height: auto;
    max-width: 30em;
    padding: 20px;
    padding-bottom: 60px;
    border-top-style: solid;
    border-top-width: 23px;
    border-top-color: #2873C9;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.308);
    font-weight: 600;
    font-size: 1em;
    line-height: 1.5em;
    }

    .content_white{
      
      height: auto;
      width: 30%;
      padding: 20px;
      padding-bottom: 60px;
      border-left-style: solid;
      border-left-width: 6px;
      border-left-color: #2873C9;
      font-family: "montserrat";
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;

      }

      #bouton{

        background-color: #2873C9;
        box-sizing: border-box;
        color: white;
        margin-top: -2vmax;
        border-style: solid;
        border-color: #c1eaf8;
        border-width: 5px;
        padding: 1.2vmax 2.4vmax;
        text-decoration: none;
        font-weight: 700;
        font-family: 'Montserrat';
        font-size: 1.2vmax;
        border-radius: 9px;
        
        
        
       }
       #bouton2{
    
        background-color: white;
        background-origin: border-box;
        box-sizing: border-box;
        color: #2873C9;
        padding: 12px 26px;
        text-decoration: none;
        font-weight: 700;
        font-family: 'Montserrat';
        font-size: 17px;
        border-style: solid;
        border-color: rgba(255, 194, 61, 0.664);
        border-width: 7px;
        border-radius: 9px;
        margin-left: -20px;
        
        
       }

       @media screen and (max-width:640px) and (min-width:200px){

        .article_content {
            max-width: 280px;
            margin-left: -25px;
            font-size: 1em;
        }
        .article_content img{
            width: 300px;

        }

        #bouton{
          
          font-size: 2vmax;
          background-color: #2873C9;
        padding: 5vmax 5vmax;
       
        font-weight: 600;
        
        border-radius: 9px;
        border-width: 5px;
       
         
          
        }
        
        .content_blue{

            font-size: 0.6em;
            
            }
        .content_blue content{
            font-size: 0.9em;
        }
        .section h1title{
            font-size: 1em;
        }
        .section{
            height: 40px;
            
        }
        .underline2{
            width: 200px;
        }
    
    }
      
     