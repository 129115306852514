


.hourlistselection{

    display: flex;
    flex-wrap: wrap;
    width: 350px;
    background: rgb(255, 245, 245);
    justify-content: center;
    
}
.rdvlist{
    
    width: 800px;
    height: 800px;
    justify-content: space-around;
    
    
}
.rdv{
    display: flex;
    justify-content: space-around;
    margin-left: 10%;
    width: 80%;
}
.rdvlist content{
    
    font-size: 15px;
    
    
    
}
.booked_rdv{
    padding: 30px;
    background: #d1f1fd;
}
.selected{
    border-style: solid;
    border-color: #2873C9;
    border-width: 4px;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.308);
}
.offhour{
    color: black;
    background-color: #d4ddd7;
    pointer-events: none;
    

}




.manage_dispo{
    height: 20px;
    
    background: white;
}
#confirm{
    margin-left: -100%;
    padding-bottom: 80px;
    margin-bottom: 50px;
    margin-top: 10%;
    border-style: solid;
    border-color:  #d0f0fc;
    border-width: 10px;
  padding: 4%;
  width: 100%;
  border-radius: 9px;
  font-weight: 500;
  font-size: 13px;
  background: white;
  align-self: center;
  text-align: center;
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.267);
  
  
  
  }
    
  
  .rdv .content_blue{

    padding-bottom: 0%;
    align-items: center;
    box-shadow: none;
    width: 100%;
    align-self: center;
  
  }

@media screen and (max-width:640px) and (min-width:200px){
    #mobile_rdv{
        display: flex;
        align-items: center;
    }
    .datetime{
        padding: 0px;
        margin: 0px;
        width: 100%;
        
    }
    #mobile_coord{
        background: none;
        align-items: flex-start;
    }
    #confirm{
        margin-left: 0%;
        border-style: none;
        width: 180%;
    }
    #schema_step{
        display: none;
    }
    #schema_rdv{
        display: none;
    }
    .note{display: none;}

.rdv{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.rdv form{
    margin-top: 10%;
}
#calendar_id{
    height: 20vmin;
}
#bouton{
    padding:1.2vmax;
    width: auto;
    margin: 0px;
}

}